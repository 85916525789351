import { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OptionsObject, enqueueSnackbar } from 'notistack';
import addMonths from 'date-fns/addMonths';
import { AppPermissions, CreatePendingInterfacesForm } from '../../types';
import {
  ResetWhenFieldChanges,
  CountrySelectFormInput,
  DatePickerFormInput,
  FlexSalesForm,
  StoreMultiSelectFormInput,
  TextFieldFormInput,
  useFlexSalesForm,
  FlexSalesFormActions,
} from '../FlexSalesForm';

import { getSchema } from './schema';

import routes from '../../config/routes';
import { PermissionsProvider } from '../Permissions';
import { useListener } from '../../hooks';
import { ActionType } from '../../state/report/types';

const maxDate = new Date();
const minDate = addMonths(maxDate, -6);

const initialValues: CreatePendingInterfacesForm = {
  country: '',
  stores: [],
  justification: '',
  businessDateFrom: null,
  businessDateTo: null,
};

const resourcePermissions = [AppPermissions.REPROCESS];

const messageOptions: OptionsObject = {
  variant: 'success',
  autoHideDuration: 6000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
};

const StoreMultiFormInput = () => {
  const { t } = useTranslation();
  const { control } = useFlexSalesForm();
  const country = useWatch({ control, name: 'country' });
  return (
    <StoreMultiSelectFormInput
      name="stores"
      countryCode={country}
      label={t('toolbar.labels.store')}
    />
  );
};

const ConditionalCountrySelectFormInput = () => (
  <CountrySelectFormInput name="country" fullWidth required />
);

const ReprocessForm = () => {
  const { t } = useTranslation();
  const resolver = useMemo(() => yupResolver(getSchema()), []);
  const formListener = useListener(
    ActionType.pendingInterfacesCreateRequested,
    ActionType.pendingInterfacesCreateSuccess,
    ActionType.pendingInterfacesCreateFailure
  );
  const navigate = useNavigate();
  const handleCancel = useCallback(() => {
    navigate(routes.protectedNestedRoutes.pendingInterfaces.index.path);
  }, [navigate]);

  const onSubmit = async (data: CreatePendingInterfacesForm) => {
    await formListener(data);
    handleCancel();
    enqueueSnackbar(t('reprocess.messages.success'), messageOptions);
  };
  return (
    <PermissionsProvider resourcePermissions={resourcePermissions}>
      <FlexSalesForm
        onValidSubmit={onSubmit}
        shouldUnregister
        resolver={resolver}
        defaultValues={initialValues}
      >
        <ResetWhenFieldChanges field="country" fieldToReset="stores" />
        <Grid container flexDirection="column" rowSpacing={1}>
          <Grid item xs>
            <ConditionalCountrySelectFormInput />
          </Grid>
          <Grid item xs>
            <StoreMultiFormInput />
          </Grid>
          <Grid item xs>
            <DatePickerFormInput
              name="businessDateFrom"
              label={t('reprocess.labels.since')}
              minDate={minDate}
              required
            />
          </Grid>
          <Grid item xs>
            <DatePickerFormInput
              name="businessDateTo"
              label={t('reprocess.labels.until')}
              required
              maxDate={maxDate}
            />
          </Grid>
          <Grid item xs>
            <TextFieldFormInput
              name="justification"
              variant="standard"
              label={t('reprocess.labels.justification')}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <FlexSalesFormActions
          onCancel={handleCancel}
          cancelLabel={t('reprocess.labels.cancel')}
          submitLabel={t('reprocess.labels.create')}
        />
      </FlexSalesForm>
    </PermissionsProvider>
  );
};

export default ReprocessForm;
