import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';

import {
  filterParametersSelector,
  reportFilterCountryCodeSelector,
  reportExecutePendingInterfacesSalesSelector,
} from '../../state';
import { ExecutionStatusPendingInterfaces } from '../../types';
import handlerFactory, { ExecutionsPendingInterfacesDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<
    ExecutionStatusPendingInterfaces,
    ExecutionsPendingInterfacesDataGridContext
  >();

  const { t } = useTranslation();
  const title = t('pending-interfaces.titles.execute_interfaces');
  const country = useSelector(reportFilterCountryCodeSelector);
  const salesResource = useSelector(reportExecutePendingInterfacesSalesSelector);
  const { data, loading } = salesResource;

  const appParameters = useSelector(filterParametersSelector);

  const gridContext = useMemo(
    () => ({
      t,
      country,
    }),
    [t, country]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(title);
  handler.setLoading(loading);

  return null;
};

const ExecutePendingInterfacesDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default ExecutePendingInterfacesDataGrid;
