import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { DialogFlexSales, PendingInterfacesForm } from '../components';

const DialogContentStyled = styled(DialogContent)`
  width: 20em;
`;

const ReprocessNew = () => {
  const { t } = useTranslation();
  return (
    <DialogFlexSales open maxWidth="sm">
      <DialogTitle>{t('pending-interfaces.labels.create_reload')}</DialogTitle>
      <DialogContentStyled>
        <PendingInterfacesForm />
      </DialogContentStyled>
    </DialogFlexSales>
  );
};

export default ReprocessNew;
