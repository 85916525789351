import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  reportPendingInterfacesParametersSelector,
  reportExecutePendingInterfacesFilterStatusSelector,
  changeExecutePendingInterfacesFilterStatus,
} from '../../../state';
import {
  SimpleSelect,
  SimpleSelectOptionFormatter,
  SimpleSelectProps,
  useArrayToSimpleSelectOption,
} from '../../selects';

type StatusFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const formatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'pending-interfaces.statuses',
  sort: 'asc',
};

const StatusFilter = (props: StatusFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parameters = useSelector(reportPendingInterfacesParametersSelector);
  const options = useArrayToSimpleSelectOption(parameters.data?.statuses || [], formatter);
  const value = useSelector(reportExecutePendingInterfacesFilterStatusSelector);
  const handleChange = useCallback(
    (value: string) => dispatch(changeExecutePendingInterfacesFilterStatus(value)),
    [dispatch]
  );

  return (
    <SimpleSelect
      {...props}
      label={t('reprocess.labels.status')}
      onChange={handleChange}
      value={parameters.loaded ? value : ''}
      options={options}
      loading={parameters.loading}
    />
  );
};

export default StatusFilter;
