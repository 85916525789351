import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MultiSelect, { MultiSelectProps } from '../MultiSelect';
import { masterDataSelector } from '../../../state';

export interface FederativeEntityMultiSelectProps
  extends Omit<MultiSelectProps<string>, 'options' | 'propDesc'> {
  countryCode: string;
}

const FederativeEntityMultiSelect = ({
  countryCode,
  translationKey,
  onChange,
  value,
  selectAll,
  disabled,
  ...others
}: FederativeEntityMultiSelectProps) => {
  const masterData = useSelector(masterDataSelector);

  const federativeEntitiesForCountry = useMemo(() => {
    if (masterData.loaded && masterData.data) {
      const entities = masterData.data.federativeEntities;
      const countries = Object.entries(entities)
        .filter(([key, value]) => key === countryCode)
        .flatMap(([, value]) => value);
      return countries;
    }
    return [];
  }, [masterData, countryCode]);

  const isDisabled = disabled || !countryCode;

  const loading = masterData.loading && !masterData.loaded;

  return (
    <MultiSelect
      {...others}
      options={federativeEntitiesForCountry}
      disabled={isDisabled}
      value={value}
      loading={loading}
      onChange={onChange}
      selectAll={selectAll}
    />
  );
};

export default FederativeEntityMultiSelect;
