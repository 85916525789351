import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Dispatch, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import routes from '../../config/routes';

import {
  changeExecutePendingInterfacesFilterCountry,
  changeExecutePendingInterfacesFilterBusinessDate,
  changeExecutePendingInterfacesFilterStore,
  reportExecutePendingInterfacesFilterBusinessDateSelector,
  reportExecutePendingInterfacesSalesLoadingSelector,
  executePendingInterfacesRequested,
  masterDataSelector,
  activeCountriesSelector,
} from '../../state';

import { ReportFilterActions, ReportFilters } from '../ReportFilters';

import { CountryFilter, StoreFilter, BusinessDateFilter, StatusFilter } from './filters';

import { dateResponseMap } from '../../helpers';
import { AppPermissions, MasterData, RemoteResource, Stores } from '../../types';
import { PermissionsProvider } from '../Permissions';

const handleSearchParams = (
  dispatch: Dispatch<AnyAction>,
  searchParams: URLSearchParams,
  masterData: RemoteResource<MasterData | undefined>
) => {
  const country = searchParams.get('country');
  const stores = searchParams.get('stores')?.split(',');
  const businesDate = dateResponseMap(searchParams.get('businessDate')) as Date;

  if (country && businesDate) {
    dispatch(changeExecutePendingInterfacesFilterBusinessDate(businesDate));
    if (country) {
      const masterDataCountries = masterData.data?.countries || [];
      const filterCountry = masterDataCountries.find(i => i.countryCode === country);
      if (filterCountry) dispatch(changeExecutePendingInterfacesFilterCountry(country));
    }
    if (stores) {
      const masterDataStores = masterData.data?.stores || [];
      const filterStores = stores.map(storeAcronym =>
        masterDataStores.find(i => i.countryCode === country && i.storeAcronym === storeAcronym)
      );
      dispatch(changeExecutePendingInterfacesFilterStore(filterStores as Stores[]));
    }
  }
};

const ButtonCreate = () => {
  const navigate = useNavigate();
  const handleClickApply = useCallback(
    () => navigate(routes.protectedNestedRoutes.pendingInterfaces.children.create.path),
    [navigate]
  );
  const { t } = useTranslation();
  const { loaded } = useSelector(activeCountriesSelector);
  const disabled = !loaded;

  return (
    <Button variant="contained" onClick={handleClickApply} disabled={disabled}>
      {t('pending-interfaces.execution.label')}
    </Button>
  );
};

const ExecutePendingInterfacesSearch = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const masterData = useSelector(masterDataSelector);
  const businessDate = useSelector(reportExecutePendingInterfacesFilterBusinessDateSelector);
  const applyDisabled = !businessDate.accept;

  useEffect(() => {
    handleSearchParams(dispatch, searchParams, masterData);
  }, [dispatch, masterData, searchParams]);

  return (
    <ReportFilters
      loadingSelector={reportExecutePendingInterfacesSalesLoadingSelector}
      applyAction={executePendingInterfacesRequested}
    >
      <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <CountryFilter fullWidth />
          </Grid>
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <StoreFilter />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <BusinessDateFilter />
          </Grid>
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <StatusFilter fullWidth />
          </Grid>
          <Grid item xs={12} md lg xl>
            <ReportFilterActions applyDisabled={applyDisabled}>
              <ButtonCreate />
            </ReportFilterActions>
          </Grid>
        </Grid>
      </PermissionsProvider>
    </ReportFilters>
  );
};

export default ExecutePendingInterfacesSearch;
