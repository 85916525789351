import { useSelector } from 'react-redux';
import { SimpleSelectOptionFormatter, useArrayToSimpleSelectOption } from '../components';
import { reprocessParametersSelector } from '../state';

const formatter: SimpleSelectOptionFormatter = {
  type: 'translation',
  translationIdBase: 'reprocess.type',
  sort: 'asc',
};

const useReprocessTypeSelect = () => {
  const parameters = useSelector(reprocessParametersSelector);
  const options = useArrayToSimpleSelectOption(
    parameters.data?.types
      .filter(item => item.category.toLocaleLowerCase() !== 'interface')
      .map(item => item.type) || [],
    formatter
  );
  const categoryOptions = parameters.data?.types || [];
  return { options, categoryOptions, loading: parameters.loading };
};

export default useReprocessTypeSelect;
