import {
  Agreement,
  Company,
  Management,
  Region,
  RegionalManagement,
  ReportFilterTenderGroupBy,
  SaleType,
  Supervision,
  TenderReport,
} from '../../types';
import { commonColProps, DataGridHandler } from '../DataGridFlexSales';

export interface ReportTenderDataGridContext {
  saleTypeOptions: SaleType[];
  companies: Company[];
  regionalManagements: RegionalManagement[];
  regions: Region[];
  managements: Management[];
  supervisions: Supervision[];
  agreements: Agreement[];
  t: (key: string | string[]) => string;
  country: string;
  groupBy: ReportFilterTenderGroupBy[];
}

const initialContext: ReportTenderDataGridContext = {
  saleTypeOptions: [],
  companies: [],
  regionalManagements: [],
  regions: [],
  managements: [],
  supervisions: [],
  agreements: [],
  t: () => '',
  country: '',
  groupBy: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<TenderReport, ReportTenderDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    visibilityTogglingDisabled: true,
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
    available: ({ groupBy }) => groupBy.includes('storeAcronym'),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
    available: ({ groupBy }) => groupBy.includes('businessDate'),
  });

  handler.addColumn({
    accessor: 'operationDate',
    type: 'date',
    title: ({ t }) => t('table.columns.operation_date'),
    available: ({ groupBy }) => groupBy.includes('operationDate'),
  });

  handler.addColumn({
    accessor: 'accountingDate',
    type: 'date',
    title: ({ t }) => t('table.columns.accounting_date'),
    available: ({ groupBy }) => groupBy.includes('accountingDate'),
  });

  handler.addColumn({
    accessor: 'tenderName',
    type: 'string',
    title: ({ t }) => t('table.columns.payment_type'),
    available: ({ groupBy }) => groupBy.includes('tenderName'),
  });

  handler.addColumn({
    accessor: 'operationTypeName',
    type: 'string',
    title: ({ t }) => t('table.columns.operation_type_name'),
    available: ({ groupBy }) => groupBy.includes('operationTypeName'),
  });

  handler.addColumn({
    name: 'saleTypeWh',
    type: 'string',
    title: ({ t }) => t('table.columns.sale_type_wh'),
    valueGetter: (row, context) => {
      if (!row.saleTypeWh) return '-';
      return context.saleTypeOptions.find(i => Number(i.saleTypeId) === Number(row.saleTypeWh))
        ?.saleTypeDescription;
    },
    available: ({ groupBy }) => groupBy.includes('saleType'),
  });

  handler.addColumn({
    accessor: 'posType',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_type'),
    available: ({ groupBy }) => groupBy.includes('posType'),
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_num'),
    available: ({ groupBy }) => groupBy.includes('posNum'),
  });

  handler.addColumn({
    accessor: 'timeBand',
    type: 'string',
    title: ({ t }) => t('table.columns.time_band'),
    available: ({ groupBy }) => groupBy.includes('timeBand'),
  });

  handler.addColumn({
    accessor: 'dayPart',
    type: 'string',
    title: ({ t }) => t('table.columns.day_part'),
    available: ({ groupBy }) => groupBy.includes('timeRange'),
  });

  handler.addColumn({
    name: 'regionalMgmtId',
    title: ({ t }) => t('table.columns.regional_mgmt_id'),
    type: 'string',
    valueGetter: (row, { regionalManagements }) =>
      regionalManagements.find(i => i.id === row.regionalMgmtId)?.label,
    available: ({ groupBy }) => groupBy.includes('regionalMgmtId'),
  });

  handler.addColumn({
    name: 'regionId',
    type: 'string',
    title: ({ t }) => t('table.columns.region_id'),
    valueGetter: (row, { regions }) => regions.find(i => i.id === row.regionId)?.label,
    available: ({ groupBy }) => groupBy.includes('regionId'),
  });

  handler.addColumn({
    name: 'mgmtId',
    type: 'string',
    title: ({ t }) => t('table.columns.mgmt_id'),
    valueGetter: (row, { managements }) => managements.find(i => i.id === row.mgmtId)?.label,
    available: ({ groupBy }) => groupBy.includes('mgmtId'),
  });

  handler.addColumn({
    name: 'supervisionId',
    type: 'string',
    title: ({ t }) => t('table.columns.supervision_id'),
    valueGetter: (row, { supervisions }) =>
      supervisions.find(i => i.id === row.supervisionId)?.label,
    available: ({ groupBy }) => groupBy.includes('supervisionId'),
  });

  handler.addColumn({
    name: 'agreementId',
    type: 'string',
    title: ({ t }) => t('table.columns.agreement_id'),
    valueGetter: (row, { agreements }) => agreements.find(i => i.id === row.agreementId)?.label,
    available: ({ groupBy }) => groupBy.includes('agreementId'),
  });

  handler.addColumn({
    name: 'companyId',
    type: 'string',
    title: ({ t }) => t('table.columns.company_id'),
    valueGetter: (row, { companies }) =>
      companies.find(i => Number(i.companyId) === Number(row.companyId))?.companyName,
    available: ({ groupBy }) => groupBy.includes('companyId'),
  });

  handler.addColumn({
    accessor: 'totalGrossSales',
    type: 'number',
    title: ({ t }) => t('table.columns.totalGrossSales'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'totalNetSales',
    type: 'number',
    title: ({ t }) => t('table.columns.totalNetSales'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'extraCash',
    type: 'number',
    title: ({ t }) => t('table.columns.extraCash'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'promotion',
    type: 'number',
    title: ({ t }) => t('table.columns.promotion'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'saleOriginalForeignCurrency',
    type: 'number',
    title: ({ t }) => t('table.columns.saleOriginalForeignCurrency'),
  });

  handler.addColumn({
    accessor: 'averagePrice',
    type: 'number',
    title: ({ t }) => t('table.columns.averagePrice'),
  });

  return handler;
};
