import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSimpleSelectOptionMapper } from '../components';
import { masterDataSelector } from '../state';

const useFederativeEntitySelect = (
  countryCode: string,
  emptySelection: string | undefined = undefined
) => {
  const masterData = useSelector(masterDataSelector);

  const federativeEntitiesForCountry = useMemo(() => {
    const federativeEntities =
      masterData.loaded && masterData.data ? masterData.data.federativeEntities : {};

    return countryCode && federativeEntities[countryCode] ? federativeEntities[countryCode] : [];
  }, [masterData, countryCode]);

  const disabled = !countryCode;
  const federativeEntities = federativeEntitiesForCountry.map(st => ({ id: st, desc: st }));
  const options = useSimpleSelectOptionMapper(federativeEntities || [], 'id', 'desc');
  if (emptySelection !== undefined && options.length && options[0].id)
    options.unshift({ id: '', description: emptySelection });

  return { options, disabled, loading: masterData.loading };
};

export default useFederativeEntitySelect;
