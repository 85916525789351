import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { executePendingInterfacesParametersRequested } from '../state';
import { PendingInterfacesDataGrid, PendingInterfacesSearch } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const PendingInterfaces = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(executePendingInterfacesParametersRequested());
  }, [dispatch]);
  return (
    <>
      <Container>
        <PendingInterfacesSearch />
        <PendingInterfacesDataGrid />
      </Container>
      <Outlet />
    </>
  );
};

export default PendingInterfaces;
